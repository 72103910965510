/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line linebreak-style */

export const environments = {
  apiStripeUrl: 'https://j1ay5c9qrd.execute-api.us-east-1.amazonaws.com/pay',
  AWS_REGION: 'us-east-1',
  AWS_API_GATEWAY: 'https://n5um5iws84.execute-api.us-east-2.amazonaws.com',
  DSIM_KEY: '',
  DSIM_SECRET: '',
  apiDrSimTools: 'https://nnrka8dc80.execute-api.us-east-1.amazonaws.com/drsim/tools',
  apiDrSimCreateOrden: 'https://nnrka8dc80.execute-api.us-east-1.amazonaws.com/drsim/create_order',
  apiGatewayOrdenTicket: 'https://0v8aexvf86.execute-api.us-east-1.amazonaws.com/tickets/ordenes',
  apiBackendAzure: 'https://desbloqueamicell.azure-api.net/backend/httpTriggerBackendDesbloqueamicell',
  apiBackendAzurePaises: '/api?tab=paises',
  apiBackendAzureOperadoras: 'https://desbloqueamicell.azure-api.net/backend/httpTriggerBackendDesbloqueamicell?tab=operadoras',
  apiBackendAzureMarcas: 'https://desbloqueamicell.azure-api.net/backend/httpTriggerBackendDesbloqueamicell?tab=marcas_celulares',
  apiBackendAzureDispositivos: 'https://desbloqueamicell.azure-api.net/backend/httpTriggerBackendDesbloqueamicell?tab=dispositivos_celulares',
};
