export const CONSTANTS = {
  desbloqueamicelULAR: 'Desbloquea mi Celular',

};

export const PATHS = [
  { name: 'Inicio', path: '/' },
  { name: 'Acerca', path: '/acerca' },
  { name: 'Contáctanos', path: '/contactanos' },
  { name: 'Desbloqueos', path: '/desbloqueos' },
  { name: 'Telefonos', path: '/telefonos' },
  // { name: 'Members', path: '/members' },
];
